@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary: rgb(1, 39, 110);
    --secondary: #d6ba86;
    --labelColor: #666
}

html {
    scroll-behavior: smooth;
}

* {
    padding: 0;
    margin: 0;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: #000;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #999 !important;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #999 !important;
    opacity: 1;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #999 !important;
    opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #999 !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #999 !important;
}

::placeholder { /* Most modern browsers support this now. */
    color: #999 !important;
}

input, select {
    box-shadow: none !important;
}

body {
    background: #fff;
    font-family: "Poppins", sans-serif !important;
    font-weight: 300 !important;
    color: #000;
}

main {
    padding-top: 120px;
    /* padding-top: 80px; */
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.btn.btn-primary {
    border-color: #F0D39C;
    background: #F0D39C;
    transition: all 0.3s ease;
    color: #000;
}

.btn.btn-primary:hover {
    border-color: #d6ba86;
    background: #d6ba86;
    color: #000;
}

.btn.btn-primary-theme {
    border-color: #022970;
    background: #022970;
    transition: all 0.3s ease;
    color: #fff;
}

.btn.btn-primary-theme:hover {
    border-color: #022970;
    background: #022970;
    color: #fff;
}

.btn.btn-secondary {
    border-color: #3ea54c;
    background: #3ea54c;
    transition: all 0.3s ease;
}

.btn.btn-secondary:hover {
    border-color: #31913e;
    background: #31913e;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.spacing-40 {
    padding: 40px 0;
}

.spacing-60 {
    padding: 60px 0;
}

.spacing-80 {
    padding: 80px 0;
}

@media screen and (max-width: 768px) {
    .spacing-60 {
        padding: 40px 0;
    }

    .spacing-80 {
        padding: 60px 0;
    }

    .spacing-40 {
        padding: 40px 0;
    }
}

@media screen and (max-width: 560px) {
    .spacing-60 {
        padding: 30px 0;
    }

    .spacing-80 {
        padding: 40px 0;
    }

    .spacing-40 {
        padding: 20px 0;
    }
}

header nav.navbar {
    /*background: #000;*/
    min-height: 120px;
    /* min-height: 80px; */
    /*box-shadow: 0 5px 0 #F0D39C;*/
}

.header-btn-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header-btn-group a.switch-portal {
    color: #fff;
    /* border: 1px solid #fff; */
    border-radius: 6px;
    box-shadow: inset 0 0 0 1px #fff;
    padding: 4px 15px;
    transition: all 0.3s ease;
}

.header-btn-group a.switch-portal b {
    font-weight: 500;
}

.header-btn-group a.switch-portal:hover {
    background: #fff;
    color: #022970;
    box-shadow: inset 0 0 0 1px #fff;
}

.header-btn-group .flex-btns {
    display: flex;
    color: #fff;
    gap: 15px;
    /*margin: 8px 0 0;*/
}

.header-btn-group .flex-btns a.btn {
    color: #fff;
    padding: 0 15px;
    border: none !important;
}

header nav.navbar ul.navbar-nav li a {
    color: #fff;
    padding: 0 !important;
    transition: all 0.3s ease;
    font-weight: 600;
    font-size: 18px;
}

header nav.navbar ul.navbar-nav li a.active {
    position: relative;
    /*margin-top: -5px;*/
    font-weight: 500;
}

header nav.navbar ul.navbar-nav {
    gap: 25px;
    /*color: #fff;*/
    color: black;
}


@media screen and (max-width: 560px) {
    header nav.navbar ul.navbar-nav {
        gap: 15px;
        margin: 15px 0 !important;
    }

    .header-btn-group {
        display: inline-block;
    }
}

header nav.navbar ul.navbar-nav li a.active::after {
    content: '';
    width: 100%;
    height: 1px;
    background: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
}

.login-module .main-head {
    text-align: center;
}

.form-section {
    position: relative;
    padding: 30px 0;
    overflow: hidden;
}

.form-section img.banner-login {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
}

.form-section form label {
    font-weight: 500;
}

.form-section form .form-control {
    border-color: #365ca2;
    font-weight: 400;
    box-shadow: none;
    padding: 7px 14px;
    min-height: 40px;
}

.form-section form .form-check input[type="checkbox"] {
    border-radius: 50px;
    box-shadow: none;
}

.form-section form .form-check input[type="checkbox"]:checked {
    background-color: #2196f3;
    border-color: #2196f3;
}

.form-section form button.btn.btn-primary {
    width: 100%;
    min-height: 40px;
    text-transform: uppercase;
    font-weight: 500;
    color: #000;
}

.download-application .card {
    background: #000;
    color: #fff;
}


.download-application .content h3 {
    font-weight: 600;
}

.download-application .flex-box {
    display: flex;
    gap: 15px;
}


@media screen and (max-width: 560px) {
    .download-application .flex-box {
        flex-direction: column;
    }
}

.download-application .flex-box form {
    width: 100%;
}

.download-application .flex-box form .form-control {
    min-height: 40px;
    border-radius: 6px;
    border: none;
    padding: 4px 15px;
    color: #000;
}

.download-application .flex-box form button.btn.btn-secondary {
    width: 100%;
    border-radius: 6px;
    font-weight: 500;
    white-space: nowrap;
    min-height: 40px;
}

.download-application .flex-box span.or {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.download-application .flex-box span.or::after {
    content: '';
    width: 1px;
    height: 40%;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: white;
    position: absolute;
}

.download-application .flex-box span.or::before {
    content: '';
    width: 1px;
    height: 40%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: white;
    position: absolute;
}


@media screen and (max-width: 560px) {
    .download-application .flex-box span.or::after {
        left: 0;
        right: unset;
    }

    .download-application .flex-box span.or::before {
        right: 0;
        left: unset;
    }

    .download-application .flex-box span.or::before,
    .download-application .flex-box span.or::after {
        height: 1px;
        width: 45%;
        top: 0;
        bottom: 0;
    }
}

.download-application .content {
    margin: 0 0 20px;
}

.download-application .flex-box .download-btns {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.download-application .flex-box .download-btns a {
    display: block;
    border-radius: 6px;
    overflow: hidden;
    height: 40px;
    width: 100%;
}

.download-application .flex-box .download-btns a img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.download-application .flex-box .scaner {
    min-width: 90px;
    overflow: hidden;
    height: 90px;
}

.download-application .flex-box .scaner img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.form-section .row {
    row-gap: 20px;
}

.footer .footer-logo {
    width: 215px;
}

.footer .social-box ul {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
    row-gap: 20px;
    flex-wrap: wrap;
}

.footer .social-box ul li a {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #000;
    text-decoration: none;
    position: relative;
    transition: all 0.3s ease;
}

.footer .social-box ul li a span {
    width: 38px;
    height: 38px;
    border-radius: 50px;
    box-shadow: 0 0 0px 1px #000;
    overflow: hidden;
    padding: 4px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.footer ul.nav.flex-column li a::after {
    content: '';
    width: 12px;
    height: 1px;
    background: transparent;
    position: absolute;
    left: -20px;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
    transition: all 0.3s ease;
}

.footer ul.nav.flex-column li a:hover::after {
    background: #032971;
    left: 0;
    opacity: 1;
}

.footer ul.nav.flex-column li a:hover {
    color: #032971;
    padding-left: 18px !important;
    transition: all 0.3s ease;
}

.footer .social-box ul li a span img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.footer .social-box ul li a span img {
    width: 20px;
    height: 20px;
}

.footer .social-box ul li a:hover span {
    transform: rotate(360deg);
}

.footer h5 {
    color: #000;
    font-size: 1rem;
    font-weight: 600;
}

.footer ul.nav.flex-column li a {
    color: #000;
    position: relative;
}

.footer .border-top {
    border-top-width: 2px !important;
    border-color: #ff0000 !important;
}

.banner {
    position: relative;
}

.banner > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    object-fit: cover;
}

.banner h1 {
    font-weight: 600;
    margin: 0 0 20px;
}

.why-choose {
    position: relative;
    color: #000;
    padding: 80px 0;
}

.why-choose > img {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.head {
    text-align: center;
}

.head h3 {
    margin: 0;
    font-weight: 600;
}

.why-choose .content-box {
    text-align: center;
}

.why-choose .content-box span {
    display: inline-block;
    width: 60px;
    height: 60px;
    overflow: hidden;
    min-width: 60px;
    margin: 0 0 20px;
}

.why-choose .content-box span img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: invert(1);
}

.note-banner h3 {
    border-radius: 6px;
    background: #000000;
    padding: 20px;
    color: #fff;
    text-align: center;
    font-size: 24px;
}

.agent-with-us .box:hover .content-box h4,
.agent-with-us .box:hover .content-box p {
    color: #000;
}

.agent-with-us .box:hover {
    background: #F0D39C;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.329);
    border-color: #0f3a8c;
    color: #000;
}

/* .agent-with-us .box:hover span img {
    filter: invert(1) brightness(10);
} */

.agent-with-us .box {
    border: 1px solid #869dc7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 30px;
    transition: all 0.3s ease;
}

.agent-with-us .box span {
    min-width: 60px;
    width: 60px;
    height: 60px;
    overflow: hidden;
}

.agent-with-us .box span img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.agent-with-us .box .content-box h4 {
    color: #000;
    font-weight: 600;
    font-size: 18px;
}

.agent-with-us .box .content-box p {
    margin: 0;
}

.agent-with-us #container {
    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    margin: auto;
}

.agent-with-us #container:hover img {
    transform: scale(1.3);
}

.agent-with-us #container .item {
    z-index: 100;
    padding: 5px;
}

.agent-with-us #container .item img {
    width: 150px;
    transition: all 0.3s ease;
    filter: invert(1) brightness(0);
}

.agent-with-us #container .circle {
    border-radius: 50%;
    background-color: #efefef;
    width: 150px;
    height: 150px;
    position: absolute;
    opacity: 0;
    animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);
}

@keyframes scaleIn {
    from {
        transform: scale(.5, .5);
        opacity: .4;
    }
    to {
        transform: scale(2.5, 2.5);
        opacity: 0;
    }
}

.review .review-slide .review-box {
    border-radius: 30px;
    /*background: rgb(240, 211, 156);*/
    background: rgba(0, 0, 0, 1);
    /*background: linear-gradient(348deg, rgba(240, 211, 156, 1) 0%, rgba(0, 0, 0, 1) 56%);*/
    /*background: linear-gradient(348deg, rgba(240, 211, 156, 1) 0%, rgba(0, 0, 0, 1) 56%);*/
    padding: 20px;
    color: #fff;
}

.review .review-slide .review-box span {
    text-align: center;
    display: block;
}

.review .review-slide .review-box h4 {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 0 8px;
}

.review .review-slide .review-box p {
    margin: 0;
}

.review .review-slide .review-box a {
    color: #fff;
    font-weight: 500;
}

.review .review-slide .review-box h3 {
    margin: 5px 0 0;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    border-top: 1px solid #fff;
    padding: 10px 0 0;
}

.review .star-box {
    background: #F0D39C;
    display: inline-block;
    border-radius: 6px;
    padding: 8px 15px;
}

.review .star-box ul {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0;
    margin: 0;
}

.review .star-box ul li img {
    width: 18px;
    filter: invert(0) brightness(100);
}

.review .review-slide .review-box span ul {
    display: flex;
    margin: 0;
    padding: 0;
    gap: 2px;
    justify-content: center;
}

.review .review-slide .review-box span ul img {
    width: 15px;
}

/* .slick-list {
    margin: 0 -15px;
}

.slick-list .slick-slide {
    margin: 0 15px;
} */

.slick-slider {
    margin: 0 -10px;
}

.slick-slide {
    margin-right: 10px;
    margin-left: 10px;
}

.review-slide .slick-arrow {
    position: absolute;
    bottom: -70px;
    right: 180px;
    background: #fff;
    left: unset;
    z-index: 1;
    top: unset;
    min-width: 30px;
    height: 30px;
    width: 30px;
    border-radius: 50px;
    box-shadow: 0 0 0 2px #F0D39C;
}

.review-slide button.slick-next.slick-arrow {
    right: 130px;
}

.review-slide .slick-arrow::before {
    opacity: 1;
    background: url(../img/arrow-red.png) no-repeat;
    background-size: 25px;
    font-family: unset;
    background-position: center;
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
}

.review-slide button.slick-next.slick-arrow::before {
    transform: rotate(180deg);
}

.review a.btn {
    min-height: 40px;
    border-radius: 51px;
    padding: 4px 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.bonus {
    position: relative;
}

.bonus .box:hover img {
    transform: scale(1.1)
}

.bonus .box img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    object-fit: cover;
    transition: all 0.3s ease;
}

.bonus .box .overlay {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    background: #000;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    bottom: 0;
    opacity: 0.85;
}

.bonus .box {
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    color: #fff;
    padding: 90px 0;
}

@media screen and (max-width: 768px) {
    .bonus .box {
        padding: 60px 0;
    }
}

.bonus .box p {
    margin: 0;
}

.bonus .note-banner h3 {
    border-radius: 0 0 6px 6px;
    padding: 0;
}

.bonus .note-banner h3 a {
    padding: 20px;
    text-decoration: none;
    color: #000;
    display: block;
    background-color: #F0D39C;
}

.bonus .shape-circle::after {
    content: '';
    max-width: 600px;
    max-height: 600px;
    width: 600px;
    height: 600px;
    position: absolute;
    left: 0;
    right: 0;
    top: 30px;
    box-shadow: 0 0 18px 0px rgba(215, 26, 33, 0.231);
    border-radius: 100%;
}

.bonus .shape-circle {
    max-width: 600px;
    max-height: 600px;
    width: 600px;
    height: 100px;
    position: absolute;
    top: -30px;
    right: 0;
    margin: auto;
    z-index: -1;
    left: 0;
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .bonus .shape-circle {
        max-width: 400px;
        max-height: 400px;
        width: 400px;
        height: 400px;
    }

    .bonus .shape-circle::after {
        content: '';
        max-width: 400px;
        max-height: 400px;
        width: 400px;
        height: 400px;
    }
}

.our-values .card {
    background: #f1f1f1;
    border-radius: 6px;
    height: 100%;
    transition: all 0.3s ease;
}


.our-values .card:hover {
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.19);
}

.our-values .card span {
    display: inline-flex;
    width: 80px;
    height: 80px;
    padding: 15px;
    text-align: center;
    margin: 0 auto 25px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: rgb(1, 39, 110);
    background: linear-gradient(90deg, rgba(1, 39, 110, 1) 0%, rgba(12, 54, 133, 1) 50%, rgba(19, 64, 148, 1) 100%);
    color: #fff;
}

.our-values .card span img {
    width: 50px;
    height: 50px;
    object-fit: contain;
}

.events .card span {
    border-radius: 6px;
    overflow: hidden;
    max-height: 350px;
    margin: 0 0 15px;
}

.events .card span img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.events .card h5 a {
    color: #0d3887;
    text-decoration: none;
}

.events .card p {
    position: relative;
    padding-left: 30px;
}

.events .card p img {
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    object-fit: contain;
    top: 0;
    bottom: 0;
    margin: auto;
}


.otp-field {
    flex-direction: row;
    column-gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.otp-field input {
    height: 45px;
    width: 42px;
    border-radius: 6px;
    outline: none;
    font-size: 1rem;
    text-align: center;
    border: 1px solid #1a4595;
}

.otp-field input:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.otp-field input::-webkit-inner-spin-button,
.otp-field input::-webkit-outer-spin-button {
    display: none;
}


.form-section .card {
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.031) !important;
}

.contact-form ul.details {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.contact-form ul.details li {
    position: relative;
    padding-left: 30px;
}

.contact-form ul.details li img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 2px;
}

.contact-form .download-application .card {
    background: transparent;
    color: #000;
    border: none;
    border-radius: 0;
}

.contact-form .download-application .flex-box .form-control {
    background: #e6e6e6;
}

.contact-form .download-application .flex-box span.or::after, .contact-form .download-application .flex-box span.or::before {
    background: #000;
}


@media screen and (max-width: 560px) {

    .note-banner h3 {
        font-size: 18px;
    }

    .why-choose .content-box span {
        margin: 0 0 10px;
    }

    .why-choose {
        padding: 60px 0;
    }
}


/****************Flight Dashboard******************/

header.dashboard nav.navbar {
    /*background: #000;*/
    background: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
}

header.dashboard nav.navbar ul.navbar-nav li a {
    /*color: #fff;*/
    color: black;
    margin: 0;
    white-space: nowrap;
}

.flight-booking {
    position: relative;
}

.flight-booking > img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    object-fit: cover;
}

.flight-booking .nav.nav-tabs .nav-item button.active {
    background-color: var(--secondary);
    color: black;
}

.flight-booking .nav.nav-tabs .nav-item button.active img {
    filter: invert(1) brightness(0);
}

.flight-booking .nav.nav-tabs .nav-item button img {
    width: 22px;
    filter: invert(1) brightness(5);
}

.flight-booking .nav.nav-tabs .nav-item button {
    color: #fff;
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
}

.radio-box {
    display: flex;
    column-gap: 25px;
    row-gap: 20px;
    flex-wrap: wrap;
}

.radio-box ul {
    display: flex;
    row-gap: 10px;
    column-gap: 20px;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

.radio-box ul li {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;
}

.radio-box ul li input[type="radio"] {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 22px;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    border-radius: 0;
}

.radio-box ul li span {
    width: 22px;
    height: 22px;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    box-shadow: inset 0 0 0 1px black;
    border-radius: 50px;
}

.radio-box ul li input[type="radio"]:checked + span::after {
    background: black;
}

.radio-box ul li span::after {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
    transition: all 0.3s ease;
    border-radius: 50px;
}

.flight-booking form .form-control {
    min-height: 52px;
    border-radius: 12px;
    border-color: #d0d8e0;
}

.flight-booking form .btn-primary {
    min-height: 52px;
}

.flight-booking input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #000 !important;
}

.flight-booking input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #000 !important;
    opacity: 1;
}

.flight-booking input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #000 !important;
    opacity: 1;
}

.flight-booking input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000 !important;
}

.flight-booking input::-ms-input-placeholder { /* Microsoft Edge */
    color: #000 !important;
}

.flight-booking input::placeholder { /* Most modern browsers support this now. */
    color: #000 !important;
}

.flight-booking select.form-control {
    -webkit-appearance: none;
    background: url(../img/down-arrow.png) no-repeat right;
    padding-right: 32px;
    background-size: 22px;
    background-position: top 15px right 15px;
}

.radio-box select.form-control {
    -webkit-appearance: none;
    background: url(../img/down-arrow.png) no-repeat right #edf0ff;
    padding-right: 42px;
    background-size: 18px;
    background-position: top 10px right 15px;
    border-color: black;
    color: black;
    font-weight: 500;
}

.clients .clients-box-slide img {
    width: 100%;
}

.clients .clients-box-slide {
    border-radius: 30px 30px 10px 10px;
    background-color: #fff;
}

.head-1 {
    text-align: center;
}

.head-1 h3 {
    margin: 0;
    font-weight: 600;
    position: relative;
    display: flex;
    justify-content: center;
    gap: 10px;
    transition: all 0.3s ease;
}

.head-1 h3::after {
    content: '';
    width: 120px;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: -10px;
    right: 0;
    margin: auto;
    background: #666666;
}

.head-1 h3 img:first-child {
    transform: rotate(180deg);
}

.head-1 h3:hover {
    gap: 25px;
}

.deal-offer .offers-box .content {
    overflow: hidden;
    border-radius: 20px;
    background: #fff;
    transition: all 0.3s ease;
}

.deal-offer .offers-box .content:hover {
    transform: translate(2px 2px);
}

.deal-offer .offers-box .content span.box {
    max-height: 200px;
    overflow: hidden;
    width: 100%;
    display: block;
    position: relative;
}

.deal-offer .offers-box .content span.box img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.deal-offer .offers-box .content h6 {
    margin: 0;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    color: #000;
}

.deal-offer .offers-box .content h6 span {
    margin-left: auto;
}

.deal-offer .offers-box .content span.box a.btn {
    position: absolute;
    right: 0;
    z-index: 1;
    top: 0;
    border-radius: 4px;
    background: #25244e;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5;
}


.deal-offer .offers-box .slick-arrow {
    position: absolute;
    /* bottom: -70px; */
    left: -5px;
    right: unset;
    background: #fff;
    /* left: unset; */
    z-index: 1;
    /* top: unset; */
    min-width: 30px;
    height: 30px;
    width: 30px;
    border-radius: 50px;
    box-shadow: 0 0 0 2px #3ea54c;
}

.deal-offer .offers-box button.slick-next.slick-arrow {
    right: -5px;
    left: unset;
}

.deal-offer .offers-box .slick-arrow::before {
    opacity: 1;
    background: url(../img/arrow-green.png) no-repeat;
    background-size: 25px;
    font-family: unset;
    background-position: center;
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
    right: 0;
    left: 0;
}

.deal-offer .offers-box button.slick-next.slick-arrow::before {
    transform: rotate(180deg);
}

.flight-booking .tab-content {
    border-radius: 30px 30px 10px 10px;
}

.flight-booking .banner-box {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 400px;
    border-radius: 0 0 30px 30px;
    width: 100%;
    overflow: hidden;
}

.flight-booking .banner-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.flight-booking .banner-box::after {
    content: '';
    width: 100%;
    height: 100%;
    /*background: #0f3a8c;*/
    /*background: #000000c2;*/
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    opacity: 0.9;
}

.flight-booking .tab-content .tab-head {
    border-bottom: 1px solid #d0d8e0;
    padding: 0 0 15px;
    margin: 0 0 20px;
    color: black;
}

.track-flights ul li.head-accordion {
    justify-content: space-between;
}

.min-width-btn {
    min-width: 80px;
}

.track-flights ul li {
    /* border-radius: 12px; */
    align-items: center;
    display: flex;
    row-gap: 15px;
    column-gap: 20px;
}

.track-flights .accordion .accordion-item {
    border-radius: 12px;
    border: 1px solid #d0d8e0;
    /* border: none; */
    margin: 0 0 15px;
}

.track-flights ul li h6.pnr {
    background: #f6f8fa !important;
    padding: 11px 15px !important;
    border-radius: 8px !important;
    border: 1px solid #d0d8e0 !important;
    max-width: 270px !important;
}

.track-flights .accordion .accordion-item:last-child {
    margin: 0;
}

.track-flights ul li h6 {
    margin: 0;
    /* display: flex; */
    display: inline-grid;
    white-space: nowrap;
    /* align-items: center; */
    flex-wrap: wrap;
    gap: 5px;
    color: black;
}

.track-flights ul li h6 small {
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    width: 100%;
    color: #000;
}

.track-flights ul li a.btn {
    white-space: nowrap;
}

.track-flights ul {
    margin: 0;
    padding: 0 0 1px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-height: 350px;
    overflow: hidden;
}

.track-flights {
    border-top: 1px solid #d0d8e0;
    margin: 20px 0 0;
    padding: 20px 0 0;
}

.track-flights ul li h6 small {
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
}

.track-flights ul li span {
    text-align: center;
    position: relative;
}

.track-flights ul li span img {
    width: 22px;
    position: relative;
}

.track-flights ul li span::after {
    content: '';
    width: 15px;
    height: 1px;
    position: absolute;
    left: -20px;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #d0d8e0;
}

.track-flights ul li span::before {
    content: '';
    width: 15px;
    height: 1px;
    position: absolute;
    right: -20px;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #d0d8e0;
}

.find-flight .flight-top-filters .radio-box button {
    color: black;
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    background-color: #edf0ff;
    box-shadow: 0 0 0 1px black;
    white-space: nowrap;
}

.find-flight .flight-top-filters .radio-box button img {
    width: 22px;
}

.find-flight .flight-top-filters {
    background-color: #fff;
}

.find-flight .flight-top-filters .double-box {
    border: 1px solid #d0d8e0;
    border-radius: 12px;
    display: inline-flex;
    justify-content: space-around;
    width: 100%;
}

.find-flight .flight-top-filters .double-box .content {
    padding: 15px;
    width: 100%;
}

.find-flight .flight-top-filters .double-box .content h5 {
    margin: 5px 0;
    color: black;
    font-size: 0.9rem;
    white-space: nowrap;
}

.find-flight .flight-top-filters .double-box .content small {
    font-size: 12px;
    max-width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.find-flight .flight-top-filters .double-box .content .dot-replace {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
}

.find-flight .flight-top-filters .double-box .divide-box {
    display: block;
    border-left: 1px solid #d0d8e0;
}

.cabin-type-modal .cabin-option .box {
    position: relative;
}

.cabin-type-modal .cabin-option .box input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.cabin-type-modal .cabin-option .box input[type="radio"]:checked + label {
    background: black;
    display: block;
    text-align: center;
    padding: 10px 15px;
    font-weight: 500;
    color: #fff;
}

.cabin-type-modal .cabin-option .box label {
    background: #f6f8fa;
    display: block;
    text-align: center;
    padding: 10px 15px;
    text-transform: uppercase;
    color: black;
    font-weight: 500;
    border-radius: 12px;
    border: 1px solid #d0d8e0;
}

.cabin-option ul {
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: masonry;
    gap: 12px;
}

.cabin-option ul {
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: masonry;
    gap: 12px;
}

.cabin-type-modal .modal-title {
    margin: 0 0 20px;
    padding: 0 0 8px;
    font-size: 1.25rem;
    font-weight: 500;
    border-bottom: 1px solid;
    border-bottom-color: #d0d8e0;
}

/* .cabin-type-modal .modal-header button.btn-close {
    position: absolute;
    right: -4px;
    top: -4px;
    opacity: 1;
    border-radius: 50px;
    background: #F0D39C var(--bs-btn-close-bg) center / 1em auto no-repeat;
    width: 25px;
    height: 25px;
    padding: 0px;
    --bs-btn-close-color: white;
    background: white;
} */

.choose-passanger .grid-box {
    margin: 0 0 15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: masonry;
}

.choose-passanger .grid-box:last-child {
    margin: 0;
}

.choose-passanger .grid-box h5 {
    margin: 0;
    font-weight: 200;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #b3b3b3;
}

.choose-passanger .grid-box h5 span {
    width: 100px;
    display: inline-block;
    color: black;
    font-weight: normal;
}

.cabin-type-modal .modal-footer {
    margin: 20px 0 0;
}

.cabin-type-modal .modal-footer button {
    border-radius: 12px;
}

.choose-passanger .grid-box .qty-input {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.choose-passanger .grid-box button.qty-count {
    width: 32px;
    height: 32px;
    border-radius: 7px;
    border: 1px solid #d0d8e0;
    background: #f6f8fa;
    color: #a9a9a9;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.choose-passanger .grid-box input.product-qty {
    height: 32px;
    margin: 0 5px;
    border: none;
    width: 40px;
    text-align: center;
    outline: none;
}

.choose-passanger .grid-box button.qty-count.qty-count--add {
    background: black;
    color: #fff;
    border-color: black;
}

/* Chrome, Safari, Edge, Opera */
.choose-passanger .grid-box input.product-qty::-webkit-outer-spin-button,
.choose-passanger .grid-box input.product-qty::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.choose-passanger .grid-box input.product-qty[type=number] {
    -moz-appearance: textfield;
}

.cabin-type-modal .modal-content {
    overflow: visible !important;
}

.cabin-type-modal button.close-btn {
    position: absolute;
    border: none;
    background: transparent;
    right: -14px;
    top: -14px;
}

.cabin-type-modal button.close-btn img {
    width: 28px;
}

.cabin-box input {
    background: url(../img/down-arrow.png) no-repeat right #ffffff;
    padding-right: 42px;
    background-size: 25px;
    background-position: top 12px right 15px;
    border-color: black;
    font-weight: 500;
}


/* Remove Arrows/Spinners */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.slider-container {
    width: 100%;
}

.slider-container {
    height: 6px;
    position: relative;
    background: #e4e4e4;
    border-radius: 5px;
}

.slider-container .price-slider {
    height: 100%;
    left: 25%;
    right: 15%;
    position: absolute;
    border-radius: 5px;
    background: black;
}

.range-input {
    position: relative;
}

.range-input input {
    position: absolute;
    width: 100%;
    height: 5px;
    background: none;
    top: -5px;
    pointer-events: none;
    cursor: pointer;
    -webkit-appearance: none;
}

.main-flight-filter .accordion {
    --bs-accordion-border-color: #ffffff;
    --bs-accordion-active-bg: transparent;
    --bs-accordion-btn-padding-x: 15px;
    --bs-accordion-btn-padding-y: 15px;
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-border-radius: 2px;
    --bs-accordion-inner-border-radius: 2px;
    --bs-accordion-body-padding-x: 15px;
    --bs-accordion-body-padding-y: 15px;
    --bs-accordion-bg: #fff;
}

.main-flight-filter .accordion button.accordion-button {
    color: black;
}

.main-flight-filter .accordion .accordion-item {
    border-radius: 12px !important;
    margin: 0 0 15px;
    overflow: hidden;
    border: 1px solid #d0d8e0;
}

.main-flight-filter .accordion .accordion-item:last-child {
    margin: 0;
}

.price-input-container .price-input {
    margin: 20px 0 0;
    display: flex;
    gap: 12px;
}

.price-input-container .price-input .price-field {
    border-radius: 12px;
    border: 1px solid #d0d8e0;
    flex-wrap: wrap;
    padding: 15px;
    width: 100%;
    display: flex;
}

.price-input-container .price-input .price-field span {
    font-size: 14px;
    margin: 0;
    display: block;
    width: 100%;
}

.price-input-container .price-input .price-field input {
    font-weight: 500;
    outline: none;
    border: none;
    width: 100%;
    color: black;
}

/* Styles for the range thumb in WebKit browsers */
.price-input-container input[type="range"]::-webkit-slider-thumb {
    height: 18px;
    width: 18px;
    border-radius: 70%;
    background: red;
    pointer-events: auto;
    -webkit-appearance: none;
}

.main-flight-filter .accordion-body {
    padding-top: 5px;
}

@media screen and (max-width: 768px) {
    .main {
        width: 80%;
        margin-right: 5px;
    }

    .custom-wrapper {
        width: 100%;
        left: 0;
        padding: 0 10px;
    }

    .projtitle {
        width: 100%;
        position: relative;
        right: 26px;
    }
}

.main-flight-filter .departure-radio .tab-pane ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.main-flight-filter .departure-radio .tab-pane ul li {
    position: relative;
}

.main-flight-filter .departure-radio .tab-pane ul li .box {
    border-radius: 12px;
    border: 1px solid #d0d8e0;
    padding: 12px;
    text-align: center;
    transition: all 0.3s ease;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
}

.main-flight-filter .departure-radio .tab-pane ul li .box img {
    height: 26px;
    object-fit: contain;
    width: 26px;
}

.main-flight-filter .departure-radio .tab-pane ul li .box h5 {
    margin: 0;
    font-size: 16px;
    color: black;
}

.main-flight-filter .departure-radio .tab-pane ul li .box small {
    font-size: 14px;
    width: 100%;
}

.main-flight-filter .departure-radio .tab-pane ul li input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.main-flight-filter .departure-radio .tab-pane ul li input[type="checkbox"]:checked + .box {
    border-color: black;
}

.main-flight-filter .departure-radio .nav-pills li button {
    width: 100%;
    border-radius: 50px;
    color: black;
    transition: all 0.3s ease;
    font-size: 14px;
    font-weight: 500;
}

.main-flight-filter .departure-radio .nav-pills li {
    width: 100%;
}

.main-flight-filter .departure-radio .nav-pills {
    display: flex;
    flex-wrap: nowrap;
    background: #f3f3f3;
    border-radius: 50px;
}

.main-flight-filter .departure-radio .nav-pills li button.active {
    background: black;
    color: #fff;
}

.main-flight-filter .radio-filter ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.main-flight-filter .radio-filter ul li {
    position: relative;
    width: 100%;
}

.main-flight-filter .radio-filter ul li input {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
}

.main-flight-filter .radio-filter ul li .box {
    border: 1px solid #d0d8e0;
    border-radius: 12px;
    padding: 12px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 40px;
}

.main-flight-filter .radio-filter ul li input[type="radio"]:checked + .box span::after {
    background: black;
}

.main-flight-filter .radio-filter ul li input[type="checkbox"]:checked + .box span::after {
    background: black;
}

.main-flight-filter .radio-filter ul li .box h5 {
    font-weight: 500;
    color: black;
    white-space: nowrap;
    margin: 0;
    font-size: 14px;
}

.main-flight-filter .radio-filter ul li .box small {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.main-flight-filter .radio-filter ul li .box span {
    min-width: 20px;
    height: 20px;
    display: inline-block;
    box-shadow: inset 0 0 0 1px black;
    border-radius: 50px;
    position: absolute;
    left: 12px;
}

.main-flight-filter .radio-filter ul li .box span::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 10px;
    height: 10px;
    background: white;
    content: '';
    border-radius: 50px;
    transition: all 0.3s ease;
}

.find-flight-main .radio-group-box {
    margin: 0 0 15px;
}

.find-flight-main .radio-group-box .radio-box {
    position: relative;
    height: 100%;
}

.find-flight-main .radio-group-box .radio-box input[type="radio"], .find-flight-main .radio-group-box .radio-box input[type="checkbox"] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.find-flight-main .radio-group-box .radio-box label {
    width: 100%;
    background: #fff;
    border-radius: 12px;
    padding: 15px;
    border: 1px solid #d0d8e0;
    color: black;
    display: flex;
    gap: 15px;
    transition: all 0.3s ease;
    height: 100%;
    align-items: center;
}

.find-flight-main .radio-group-box .radio-box label span {
    font-size: 14px;
    color: #000;
    transition: all 0.3s ease;
}

.find-flight-main .radio-group-box .radio-box label h6 {
    white-space: nowrap;
    min-width: 100px;
}

.find-flight-main .radio-group-box .radio-box input[type="radio"]:checked + label,
.find-flight-main .radio-group-box .radio-box input[type="checkbox"]:checked + label {
    background: black;
    color: white;
    border-color: black;
}

.find-flight-main .radio-group-box .radio-box input[type="checkbox"]:checked + label h6 {
    color: white;
}

.find-flight-main .radio-group-box .radio-box input[type="radio"]:checked + label span,
.find-flight-main .radio-group-box .radio-box input[type="checkbox"]:checked + label span {
    color: #fff;
}

.find-flight-main .track-flights {
    border-top: 0;
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid #d0d8e0;
}

.find-flight-main .book-flight {
    background: #fff;
    border-radius: 12px;
    padding: 15px;
    border: 1px solid #d0d8e0;
}

.find-flight-main .track-flights ul li small {
    font-size: 12px;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.find-flight-main .track-flights ul li span.logo {
    width: 50px;
    height: 50px;
    background: #e5e5e5;
    /*border-radius: 50px;*/
    border-radius: 5px;
    overflow: hidden;
    min-width: 50px;
}

.find-flight-main .track-flights ul li span.logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.find-flight-main .book-flight .total-price {
    background: #f6f8fa;
    padding: 10px;
    text-align: center;
    width: 100%;
    border-radius: 8px;
}

.find-flight-main .book-flight .total-price span {
    font-size: 12px;
    display: block;
    margin: 15px 0 0;
}

.find-flight-main .book-flight .total-price h5 {
    margin: 0;
    color: black;
}

.find-flight-main .book-flight .total-price small {
    font-size: 12px;
    display: block;
    color: #F0D39C;
    margin: 0 0 20px;
    width: 100%;
}

.find-flight-main .book-flight .total-price button {
    font-size: 14px;
    width: 100%;
}


.class-price .main-box {
    position: relative;
    width: 100%;
}

.class-price .main-box input {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
}

.class-price .main-box .box {
    justify-content: space-between;
    display: flex;
    align-items: center;
    gap: 2px;
    padding-left: 30px;
    flex-wrap: wrap;
}

.class-price .main-box input[type="radio"]:checked + .box span::after {
    background: black;
}

.class-price .main-box .box h5 {
    font-weight: 500;
    color: black;
    white-space: nowrap;
    margin: 0;
    width: 100%;
    font-size: 14px;
}

.class-price .main-box .box small {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    display: block;
}

.class-price .main-box .box span {
    min-width: 20px;
    height: 20px;
    display: inline-block;
    box-shadow: inset 0 0 0 1px black;
    border-radius: 50px;
    position: absolute;
    left: 0;
}

.class-price .main-box .box span::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 10px;
    height: 10px;
    background: white;
    content: '';
    border-radius: 50px;
    transition: all 0.3s ease;
}

.class-price button.btn.btn-grey {
    border-color: #f3f3f3;
    background: #f6f8fa;
    transition: all 0.3s ease;
    font-size: 14px;
    color: #000;
}

.flight-accordion-details .accordion .accordion-item {
    border-radius: 12px;
    border: 1px solid #d0d8e0;
    padding: 15px;
    background: #f6f8fa;
}

.flight-accordion-details .accordion .accordion-item ul {
    gap: 15px;
    border: none;
}

.flight-accordion-details .accordion .accordion-item ul button {
    border-radius: 50px;
    font-size: 14px;
    color: #bfbfbf;
    background: #fff;
    font-weight: 400;
    border: 1px solid #d0d8e0;
    text-transform: capitalize;
}

.flight-accordion-details .accordion .accordion-item ul button.active {
    border-color: black;
    color: black;
    background: #f6f8fa;
}

.flight-accordion-details .accordion .accordion-item h2.accordion-header {
    gap: 15px;
}

.flight-accordion-details .accordion .accordion-item .accordion-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

.flight-accordion-details .accordion .accordion-item .accordion-body .tab-content {
    border-top: 1px solid #d0d8e0;
    padding: 15px 0 0;
}

.flight-destimation-time {
    border-radius: 12px;
    padding: 10px 15px;
    background: black;
    color: #fff;
}

.flight-destimation-time p {
    font-size: 14px;
}

.flight-destimation-time span img {
    filter: invert(1) brightness(10);
}

.flight-destimation-time span {
    position: relative;
}

.flight-destimation-time span::after {
    content: '';
    width: 15px;
    height: 1px;
    position: absolute;
    left: -20px;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #d0d8e0;
}

.flight-destimation-time span::before {
    content: '';
    width: 15px;
    height: 1px;
    position: absolute;
    right: -20px;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #d0d8e0;
}

.accordion-flight-track .track-flights {
    background: #fff;
    border-radius: 12px;
    border: 1px solid #d0d8e0;
    margin: 15px 0 0;
    padding: 15px;
}

.accordion-flight-track .track-flights small {
    max-width: unset !important;
}

.accordion-flight-track .track-flights span {
    position: relative;
}

.accordion-flight-track .track-flights span p {
    position: absolute;
    font-size: 12px;
    white-space: nowrap;
    color: #F0D39C;
    top: -17px;
    left: -20px;
}

.accordion-flight-track p.line {
    margin: 15px 0 0;
    font-size: 14px;
    padding: 15px 0 0;
    border-top: 1px solid #d0d8e0;
}

.accordion-flight-track p.note {
    background: #fff;
    border-radius: 12px;
    border: 1px solid #d0d8e0;
    padding: 10px 15px;
    margin: 15px 0 0;
    font-weight: 500;
    color: #000000;
    text-align: center;
}


.flight-custom-table .head {
    border-radius: 8px;
    background: #000000;
    padding: 10px 15px;
    display: flex;
}

.flight-custom-table .head h5 {
    margin: 0;
    /* width: 100%; */
    text-align: left;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    min-width: 276px;
}

.flight-custom-table .content-box {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #d0d8e0;
}

.flight-custom-table .content-box ul {
    padding: 10px 15px;
    margin: 0;
}

.flight-custom-table .content-box ul li {
    padding: 5px 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.flight-custom-table .content-box ul span {
    min-width: 265px;
    display: inline-block;
    /* width: 100%; */
}

@media screen and (max-width: 580px) {

    .flight-custom-table .content-box ul span,
    .flight-custom-table .head h5 {
        width: 100%;
        min-width: unset;
    }

    .flight-custom-table .head h5,
    .flight-custom-table .content-box ul li {
        font-size: 14px;
        white-space: nowrap;
    }
}

.rules ul {
    padding: 0;
    font-size: 14px;
    list-style: disc;
    padding-left: 30px;
    margin: 0;
}

.rules {
    margin: 15px 0 0;
}

.sort-box {
    display: flex;
    align-items: center;
    width: 100%;
    background: #fff;
    border-radius: 12px;
    padding: 5px 15px;
    border: 1px solid #d0d8e0;
    margin: 0 0 15px;
    font-size: 14px;
    gap: 15px;
}

.sort-box ul {
    display: flex;
    gap: 15px;
    width: 100%;
}

.sort-box ul li {
    width: 100%;
}

.sort-box span {
    white-space: nowrap;
    color: black;
}

.sort-box .btn.btn-primary-theme {
    height: 34px;
    white-space: nowrap;
    font-size: 14px;
}

.sort-box ul li button {
    border: none;
    background: transparent;
}

.sort-box ul li button img {
    width: 19px;
    height: 10px;
    margin-top: 4px;
}

.accordion .accordion-header a img {
    transition: all 0.3s ease;

}

.accordion .accordion-header a {
    border: none;
}

.accordion a[aria-expanded="true"] img {
    transform: rotate(180deg);
}

.divide-book-flight {
    margin: 15px 0 0;
}

.grand-flight-total .track-flights ul {
    flex-direction: row;
}

.grand-flight-total .track-flights ul li {
    column-gap: 10px;
}

.grand-flight-total .track-flights {
    border: none;
    margin: 0;
    padding: 0;
}

.grand-flight-total .track-flights ul li h6 {
    color: #fff;
    font-size: 14px;
    white-space: nowrap;
}

.grand-flight-total .track-flights ul li small {
    color: #fff;
    font-size: 10px;
}

.grand-flight-total {
    background: #000000;
    padding: 15px;
    border-radius: 12px;
    color: #fff;
    font-size: 12px;
}

.grand-flight-total .track-flights ul {
    align-items: center;
    gap: 10px;
}

.grand-flight-total .track-flights ul li div span img {
    filter: invert(1) brightness(10);
}

.grand-flight-total .track-flights ul li div span::after, .grand-flight-total .track-flights ul li div span::before {
    display: none;
}

.grand-flight-total .track-flights ul li.divide {
    height: 40px;
    border-left: 1px solid #fff;
}

.grand-flight-total .track-flights ul li .price {
    background: #fff;
    /*font-size: 14px;*/
    font-size: 0.825rem;
    border-radius: 6px;
    padding: 5px 10px;
    margin: 0 0 4px;
    color: #000000;
    font-weight: 500;
    margin: 0;
}

.grand-flight-total .track-flights ul li span.logo {
    width: 40px;
    height: 40px;
    min-width: 40px;
}

.grand-flight-total .track-flights ul li .price.red-box {
    background: #F0D39C;
    font-weight: 500;
    /*color: #fff;*/
    color: black;
}

.grand-flight-total .track-flights ul li .price.red-box small {
    color: black;
}

.grand-flight-total ul::-webkit-scrollbar-thumb {
    background-color: #000000;
}


.overflow-auto::-webkit-scrollbar-track {
    border-radius: 50px;
    background-color: #ededed;
}

.overflow-auto::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #F5F5F5;
    border-radius: 50px;
    padding: 20px 0 0;
}

.overflow-auto::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: #474747;
}

.flight-booking .track-flights ul li h6 {
    display: flex;
}

.checkout-box .flight-booking .nav.nav-tabs .nav-item button {
    color: #d4dbe3;
    box-shadow: inset 0 0 0 1px #d4dbe3;
}

.checkout-box .flight-booking .nav.nav-tabs .nav-item button.active {
    color: black;
    box-shadow: inset 0 0 0 1px black;
}

.checkout-box .flight-booking .nav.nav-tabs .nav-item button img {
    filter: invert(1) brightness(0);
    opacity: 0.1;
}

.checkout-box .flight-booking .nav.nav-tabs .nav-item button.active img {
    filter: invert(0) brightness(1);
    opacity: 1;
}

.checkout-box .head span {
    color: red;
    font-weight: 500;
}

/*.checkout-box .head button img {*/
/*    transform: rotate(-90deg);*/
/*}*/

.checkout-box .detail-box {
    background: #fff;
    border-radius: 12px;
    border: 1px solid #d0d8e0;
    padding: 15px;
}

.checkout-box .detail-box h4 {
    margin: 0 0 15px;
    padding: 0 0 10px;
    border-bottom: 1px solid #d0d8e0;
    font-size: 16px;
    font-weight: 500;
    color: black;
}

.checkout-box .detail-box h4 span {
    font-size: 14px;
    color: #000;
}

.checkout-box .detail-box ul {
    padding: 0;
    margin: 0 0 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.checkout-box .detail-box ul li {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.checkout-box .detail-box ul li span {
    margin-left: auto;
}

.checkout-box .detail-box input.form-control {
    background: #f6f8fa;
    border: 1px solid #d0d8e0;
    font-size: 14px;
    text-align: center;
    margin: 0 0 10px;
}

.passanger-details .title h5 {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    background: black;
    height: 35px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    border-radius: 8px;
    white-space: nowrap;
}

.passanger-details .title ul {
    margin: 0;
    padding: 0;
    width: 100%;
}

.passanger-details .title ul li {
    width: 100%;
    text-align: center;
    position: relative;
}

.passanger-details .title ul li input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    cursor: pointer;
}

.passanger-details .title ul li label {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #d0d8e0;
    transition: all 0.3s ease;
}

.passanger-details .title ul li input[type="radio"]:checked + label {
    background: #edf0ff;
    color: black;
    border-color: black;
}

.passanger-details form {
    border-top: 1px solid #d0d8e0;
    padding: 20px 0 0;
}

.passanger-details .accordion .accordion-body {
    padding-top: 0;
}

.passanger-details form select.form-control {
    background: url(../img/down-arrow.png) no-repeat right #ffffff !important;
    background-size: 25px !important;
    background-position: top 8px right 10px !important;
}

.passanger-details form .form-control {
    border-color: #d0d8e0;
    border-radius: 8px;
    background: #fff;
    font-weight: 400;
    min-height: 40px;
}

.passanger-details .head-accordion button.btn {
    color: #999999;
    border: none;
}

.passanger-details .head-accordion button.btn.active {
    color: black;
}

.payment-method {
    margin: 15px 0 0;
    background: #fff;
    border-radius: 12px;
    border: 1px solid #d0d8e0;
    padding: 15px;
}

.payment-method h4 {
    color: #000000;
    font-size: 16px;
    margin: 0 0 15px;
    border-bottom: 1px solid #d0d8e0;
    padding: 0 0 15px;
}

.passanger-details .payment-method form {
    border: none;
    padding: 0;
}

.passanger-details .payment-method .nav-tabs {
    margin: 0 0 15px;
    border: none;
    gap: 15px;
}

.passanger-details .payment-method .nav-tabs li button {
    font-size: 16px;
    border-radius: 8px;
    padding: 5px 20px;
    background: #f6f8fa;
    color: #bfbfbf;
    border: 1px solid #d0d8e0;
}

.passanger-details .payment-method .nav-tabs li button.active {
    border-color: black;
    background: black;
    color: #fff;
}

.choose-mode ul {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 15px;
    align-items: center;
}

.choose-mode ul li {
    position: relative;
}

.choose-mode ul li input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    margin: auto;
}

.choose-mode ul li label {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    background: #f6f8fa;
    border-radius: 8px;
    min-width: 90px;
    transition: all 0.3s ease;
}

.choose-mode ul li label img {
    width: 55px;
    height: 25px;
    object-fit: contain;
}

.choose-mode {
    margin: 0 0 20px;
}

.choose-mode ul li input[type="radio"]:checked + label {
    box-shadow: inset 0 0 0 1px #d0d8e0;
}

.terms-check input[type="checkbox"] {
    position: absolute;
    width: 30px;
    height: 30px;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}

.terms-check input[type="checkbox"] + span img {
    opacity: 0.2;
    width: 30px;
    transition: 0.3s ease;
}

.terms-check input[type="checkbox"]:checked + span img {
    opacity: 1;
}

.terms-check {
    font-size: 14px;
}

.terms-check a {
    color: #000;
}

header nav.navbar ul.navbar-nav .pic {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50px;
    margin-right: 10px;
}

header nav.navbar ul.navbar-nav .pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

header nav.navbar ul.navbar-nav li span {
    display: flex;
    align-items: center;
}

header nav.navbar ul.navbar-nav li span small {
    font-size: 16px;
    max-width: 110px;
    min-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.manage-box {
    box-shadow: 0 0 11px 0px rgba(0, 0, 0, 0.10);
    border-color: #e5e5e5 !important;
    padding: 15px !important;
}

.manage-box h5 {
    font-size: 16px;
}

.manage-box h5 span {
    font-size: 12px;
}

.manage-box li {
    margin: 10px 0 0;
}

header nav.navbar ul.navbar-nav .manage-box li a.dropdown-item {
    font-size: 14px;
    padding: 5px 15px !important;
    border-radius: 50px;
    background: #f5f7fb;
    border: 1px solid black;
    display: flex;
    gap: 10px;
    color: #000;
    align-items: center;
}

header nav.navbar ul.navbar-nav .manage-box li a.dropdown-item.red {
    background: #fdf1f2;
    border: 1px solid #F0D39C;
}

.find-flight .flight-top-filters .double-box .divide-box img {
    position: absolute;
    left: -18px;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 30px;
}

.dashboard-box table.table {
    font-size: 14px;
}

.dashboard-box table.table td {
    vertical-align: middle;
}

.dashboard-box table.table th {
    font-weight: 500;
    color: #999999;
}

.dashboard-box table.table tbody tr:last-child td {
    padding-bottom: 0;
    border: none;
}

.dashboard-box .card-box {
    border-radius: 12px;
    border: 1px solid #d0d8e0;
}

.search-box {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #d0d8e0;
    overflow: hidden;
    gap: 8px;
    padding-left: 15px;
}

.search-box input {
    width: 100%;
    height: 34px;
    border: none;
    outline: none;
}

.search-box button {
    border-radius: 6px;
}

.dashboard-box .card-box h6 {
    color: #000000;
}

.card-box .box {
    border-bottom: 1px solid #d0d8e0;
}

.form-switch .form-check-input {
    border-color: #bfbfbf !important;
    width: 40px !important;
    height: 20px !important;
}

.form-check-input:checked {
    background-color: #0db561 !important;
    border-color: #0db561 !important;
}

.dashboard-box {
    position: relative;
}

.dashboard-box .sidenav {
    min-width: 330px;
    width: 330px;
    /*background: rgb(1, 39, 110);*/
    background: black;
    /*background: linear-gradient(90deg, rgba(1, 39, 110, 1) 0%, rgba(12, 54, 133, 1) 50%, rgba(19, 64, 148, 1) 100%);*/
    color: #fff;
    padding: 25px;
    min-height: calc(100vh - 120px);
    position: sticky;
    top: 0;
}

.dashboard-box .sidenav ul {
    padding: 0;
    margin: 56px 0 0;
    position: sticky;
    top: 176px;
}

.dashboard-box .sidenav ul li {
    margin: 0 0 5px;
}

.dashboard-box .sidenav ul li:last-child {
    margin: 0;
}

.dashboard-box .sidenav ul li a {
    display: flex;
    width: 100%;
    background: transparent;
    border-radius: 12px;
    padding: 10px 20px;
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    align-items: center;
    gap: 10px;
    transition: all 0.3s ease;
    min-height: 44px;
}

.dashboard-box .sidenav ul li a img {
    width: 18px;
}

.dashboard-box .sidenav ul li a span {
    display: block;
    width: 100%;
    white-space: nowrap;
}

.dashboard-box .sidenav ul .accordion-item {
    border-radius: 12px;
    overflow: hidden;
    background: transparent !important;
}

.dashboard-box .sidenav ul .accordion-item button.accordion-button img {
    width: 18px;
    filter: invert(1) brightness(5);
}

.dashboard-box .sidenav ul .accordion-item .accordion-header {
    border: none !important;
    background: transparent !important;
}

.dashboard-box .sidenav ul .accordion {
    --bs-accordion-border-width: 0;
    --bs-accordion-active-bg: #fff;
    --bs-accordion-active-color: #000;
    --bs-accordion-color: #fff;
    --bs-accordion-color: #fff;
}

.dashboard-box .sidenav ul .accordion-item button.accordion-button {
    gap: 10px;
    border-radius: 12px !important;
    box-shadow: none;
    font-weight: 500;
}

.dashboard-box .sidenav ul .accordion ul {
    margin-top: 8px;
    padding-left: 28px;
}

.dashboard-box .sidenav ul li a.active {
    background: #fff;
    color: #000;
}

.dashboard-box .sidenav .slide-btn {
    position: absolute;
    right: -15px;
    border-radius: 50px;
    border: none;
    min-width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    background: #f6f8fa;
    justify-content: center;
    transition: all 0.3s ease;
}

.dashboard-box .sidenav .slide-btn img {
    width: 16px;
}

.dashboard-box .sidenav.close-sidenav .slide-btn {
    transform: rotate(180deg);
}

.dashboard-box .sidenav.close-sidenav {
    width: 140px;
    min-width: 140px;
}

.dashboard-box .sidenav.close-sidenav ul li a span,
.dashboard-box .sidenav.close-sidenav ul .accordion-item button.accordion-button span {
    display: none;
    overflow: hidden;
    opacity: 0;
}

.form-section select {
    background: url(../img/down-arrow.png) no-repeat right #ffffff;
    background-size: 25px;
    background-position: top 8px right 15px;
    border-color: black;
    font-weight: 500;
}

header .navbar-toggler {
    background: black;
}

.mt10 {
    margin-top: 10px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.mr10 {
    margin-right: 10px !important;
}

.ml10 {
    margin-left: 10px !important;
}

.solidBorder {
    border: 1px solid lightgray;
    padding: 8px !important;
}

.themeBgColor {
    background: #F0D39D;
}

.themeLightBgColor {
    background: #fcf2e2;
}

.bold-text {
    font-weight: bold !important
}

.manageBookingList {
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
}

.ticketBtn {
    color: green;
    width: 100%;
    background: #b9e5b9;
}

.customCollapse {
    background: #f3f3f3 !important;
    padding: 7px;
    font-size: 15px;
}

.customCollapse.expanded {
    background: #f3f3f3;
}


.alert-popup {
    text-align: center;
    padding: 30px;
}

.popup-confirm {
    border-radius: 20px;
    height: fit-content !important;
    box-shadow: 0px 4px 4px 0px rgba(155, 155, 155, 1);
}

.alert-popup h3 {
    font-weight: 700;
    font-size: 23px;
}

.alert-popup p {
    font-size: 17px;
}


.customYesBtn, .customNoBtn {
    border-radius: 50px;
    background: linear-gradient(90deg,
    #F0D39C 0%,
    #F2DCB2 50%,
    #F4E1BD 100%);
    padding: 9px 34px;
    border: none;
    font-weight: bold;
}

.customNoBtn {
    background: #9b9b9b;
    color: white;
}


.seatrow {
    list-style: none;
    display: grid;
    clear: both;
    margin: 0;
    padding: 3px;
    grid-template-columns: repeat(4, 1fr);
}

.seatrow li {
    float: left;
    height: 20px;
    border-radius: 3px;
    border: 1px solid #c4c4c4;
    padding: 0 1px 1px;
    background: #fff;
}

.seatrow li span {
    background: #f7f7f7;
    border: 1px solid #c5c5c5;
    border-radius: 0 0 3px 3px;
    content: "";
    float: left;
    height: 16px;
    margin: 0 1px;
    width: 20px;
    font-size: 9px;
    line-height: 11px;
    text-align: center;
    padding: 3px 0;
}


.seat-code.selected {
    background: #509c44;
    color: white;
}

.seat-code.reserved {
    background: #bfbdbe;
    color: white;
}

.tooltip-content {
    position: absolute;
    background-color: white;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    font-size: 12px;
    z-index: 10;
    white-space: nowrap;
    display: none;
}

.seat:hover .tooltip-content {
    display: block;
}


.seat-color-guide {
    margin-top: 20px;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
}

.color-boxes {
    display: flex;
    flex-direction: column;
}

.color-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
}

.color-box.reserved {
    background: #bfbdbe;
    color: white;
}

.color-box.selected {
    background: #509c44;
    color: white;
}

.color-box span {
    margin-left: 10px;
    font-weight: bold;
}

.seatrow li, .seathint {
    float: left;
    height: 22px;
    border-radius: 3px;
    border: 1px solid #c4c4c4;
    padding: 0 1px 1px;
    background: #fff;
}

.seatrow li span, .seathint span {
    background: #f7f7f7 none repeat scroll 0 0;
    border: 1px solid #c5c5c5;
    border-radius: 0 0 3px 3px;
    content: "";
    float: left;
    height: 18px;
    margin: 0 1px;
    width: 22px;
    font-size: 9px;
    line-height: 11px;
    text-align: center;
    padding: 3px 3px;
}

/*.seatrow li.poor, .quitezone{background:#F4D4FC;}*/
.seatrow li.poor span, .quitezone span {
    background: #DD7FF4;
    border: 1px solid transparent;
    color: #fff;
}

/*.seatrow li.good, .hintgood{background:#68bd56;}*/
.seatrow li.good span, .hintgood span {
    background: #509c44;
    border: 1px solid transparent;
    color: #fff;
}

.seatrow li.drawback, .hintdrawback {
    background: #f1f160;
}

.seatrow li.drawback span, .hintdrawback span {
    background: #c4c14e;
    border: 1px solid transparent;
    color: #fff;
}

.seatrow li.booked, .hintbooked {
    background: #eaebed;
}

.seatrow li.booked span, .hintbooked span {
    background: #bfbdbe;
    border: 1px solid transparent;
}

.seatrow li.selected, .hintselected {
    background: #ffd18f;
}

.seatrow li.selected span, .hintselected span {
    background: #fcb040;
    border: 1px solid transparent;
}

.blankrow li, li.blankseat {
    visibility: hidden;
    width: 26px;
}

.blankrow li span, li.blankseat span {
    display: none;
}

.recline {
    padding-top: 10px;
}


.seatnumrow {
    display: table-cell;
    list-style: none;
    vertical-align: top;
}

.seatnumrow ul {
    position: absolute;
    margin-top: 28px;
    right: 530px;
    width: 20px;
    list-style: none;
}

.seatnumrow li {
    background: #444;
    color: #fff;
    padding: 3px 2px;
    float: left;
    margin: 5px 0 0;
    font-size: 11px;
    height: 15px;
    width: 15px;
    line-height: 9px;
}

.blanknum {
    background: none;
    height: 10px !important;
    margin: 0 !important;
    background: transparent !important;
}

.hintbox {
    float: left;
    width: 100%;
    background: #fff;
    border: 1px solid #c7c7c7;
    font-size: 14px;
    margin-top: 15px;
}

.hintrow {
    float: left;
    width: 100%;
    border: 1px solid #e7e7e7;
    border-left: none;
    border-right: none;
    margin-top: -1px;
}

.hintrow span {
    padding: 3px;
    float: left;
}

.hintseat {
    border-right: 1px solid #d7d7d7;
}

.seatrow.quitejone {
    background: #f4d4fc;
}

.bulkhead::after {
    border-top: 2px solid #000;
    content: "";
    height: 2px;
    margin-left: -26px;
    margin-top: -5px;
    position: absolute;
    width: 9%;
}



.segment-rows.hidden {
    display: none;
}

.segment-rows.active {
    display: block;
}

.label-bold {
    font-weight: 500 !important;
}

.option-form {
    font-size: 14px;
}

.deck-label {
    margin-bottom: 10px;
    font-size: 0.875rem;
    text-align: left;
    background: lightgrey;
    padding: 2px 10px;
    display: inline-block;
}

.row-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
}

.rules-icon span {
    color: red;
    font-weight: bold;
}

@media (max-width: 768px) {
    .popup-confirm {
        width: 90% !important;
        height: auto;
    }
}

.airlineLogoSize {
    width: auto;
    height: 35px;
    margin-right: 5px;
    margin-top: 3px;
    border-radius: 4px;
}

.passengersStyle {
    color: black;
}

.passengerBlock {
    width: 250px;
}

.updateMarginBtn {
    margin-top: 10px !important;
    margin-left: 8px !important;
}

.ConfirmedStyle {
    background: #95C449;
    padding: 10px;
    border-radius: 0 10px 0 5px;
    color: white;
    margin-top: -45px;
    margin-right: -16px;
    font-size: 15px !important;
}

.lowerButtonUl {
    margin-top: -25px !important;
}

.viewButtonDark {
    background: #d3d6db;
    padding: 20px 12px 17px 12px;
    border-radius: 5px;
    margin-right: -15px;
}

.downloadButtonDark {
    background: #eec16d;
    border-radius: 10px;
}

.downloadDiv {
    text-decoration: none !important;
}

.airLogoDiv {
    margin-left: -92px !important;
}

.gradient-button {
    background: linear-gradient(90deg, #F0D39D, #f5ebd6) !important;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 5px !important;
}

.downloadButtonStyle {
    color: black;
    padding: 10px;
    font-size: 15px;
}

.passengersPopup {
    background: #f6f8fa !important;
    border-radius: 5px !important;
    border: 1px solid #d0d8e0 !important;
    min-width: 110px !important;
}

.iconheight {
    height: 25px !important;
    width: auto !important;
}

.buttonFontSize {
    font-size: 14px;
    padding: 11px 20px 11px 20px;
}

.cabinClassDiv {
    margin-left: -71px !important;
}

.downloadInvoiceIcon {
    width: auto;
    max-width: 244px !important;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
}

.iconheight2 {
    max-width: 100%;
    height: auto;
}
.downloadIcon {
    margin-left: 22px;
}
