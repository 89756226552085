.react-tel-input {
    display: flex;
    align-items: center;
}

.react-tel-input .form-control {
    height: 38px !important;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    width: 100% !important;
    padding-left: 65px;
}

.react-tel-input .flag-dropdown {
    border: 1px solid #dee2e6;
}

.react-tel-input .country-list .country {
    padding: 0 12px;
}

.flag-dropdown .country-list {
    max-height: 400px;
    margin-top: 1px;
    border-radius: 5px;
}

.flag-dropdown .country-list li {
    text-align: left;
}

/* width */
.flag-dropdown .country-list::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.flag-dropdown .country-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.flag-dropdown .country-list::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.flag-dropdown .country-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.search {
    z-index: 2;
}

.search .search-box {
    width: 98% !important;
    margin: 0 !important;
    padding: 8px 10px !important;
}

.react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 55px;
    height: 100%;
    padding: 0 0 0 15px;
    border-radius: 3px 0 0 3px;
}

.track-flights .flag-dropdown ul li {
    row-gap: 0;
    column-gap: 0;
}

.track-flights .flag-dropdown ul li span::before, .track-flights .flag-dropdown ul li span::after {
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: transparent;
}

.react-tel-input .selected-flag .arrow {
    left: 25px;
}
