/* bookingDetails.css */
.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.p-2 {
    padding: 2rem;
}

.table-style {
    width: 800px;
    font-size: 13px;
    font-family: Arial, Sans-Serif;
}

.table-header {
    font-size: 13px;
    color: #333;
}

.table-header-cell {
    font-weight: bold;
    padding: 4px;
    border: 1px solid #E1E1E1;
}

.table-cell {
    padding: 5px;
    border: 1px solid #E1E1E1;
}

.logoImage {
    height: 100px;
    width: auto;
}

.topName {
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
    margin-right: 0;
    text-align: right !important;
}

.gross-table-whole {
    border-radius: 25px;
    padding: 20px;
    font-size: 13px;
    font-family: Arial, Sans-Serif, serif;
    margin-left: auto;
    float: right;
    margin-top: 20px;
    width: 100% ;
    line-height: 1;
}
.gross-table {
    border: 2px solid #E1E1E1;
    border-radius: 25px;
    padding: 20px;
    font-size: 13px;
    font-family: Arial, Sans-Serif, serif;
    margin-left: auto;
    float: right;
    width: 80% ;
}

.gross-table td {
    padding: 4px;
    width: 100%;
}

@media print {
    * {
        margin: 0;
        padding: 0;
    }
}
