.form-label {
  font-size: 0.85rem;
  padding-left: 10px;
  margin-bottom: 3px;
  font-weight: 400;
  color: black;
  opacity: 0.7;
}
.cabin-type-modal .modal-footer button {
  min-height: 52px;
}
.stop-label {
  position: absolute;
  font-size: 12px;
  white-space: nowrap;
  color: #d71a21;
  top: -17px;
  left: -20px;
}
.passenger-title-border {
  border-bottom: 1px solid #d0d8e0;
  margin: 0 0 20px 0 !important;
}
.passenger-title-border .head-accordion {
  padding: 0.3rem 1rem 1rem 0;
}
.custom-form-box .track-flights {
  background: #fff;
  border-radius: 12px;
  border: 1px solid #d0d8e0;
  margin: 15px 0 0;
  padding: 15px;
}
.custom-form-control {
  min-height: 52px;
  border-radius: 12px;
  border-color: #d0d8e0;
}
.custom-date-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
}
.custom-date-grid.w-100 {
  width: 100%;
}
.align-center {
  text-align: center;
}
.loader_outer_view {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99999 !important;
  background: #ffffffba;
  display: flex;
  justify-content: center;
  top: 0;
}
.loader_outer_view .sk-chase {
  margin-left: auto;
  margin-right: auto;
}
.loader_outer_view .sk-chase img {
  margin-top: 10vh;
  height: 40vh;
}
.loader_outer_view .loader_inner {
  height: 100vh;
  position: absolute;
  top: 0;
  padding-top: 10%;
  text-align: center;
}
.loader_outer_view .spinner-border {
  height: 50px;
}
.loader_outer_view .spinnerText {
  font-size: 40px;
  text-align: center;
  color: #3a3a44;
  letter-spacing: 10px;
}
.summary-grid {
  display: grid;
  grid-template-columns: 60% 40%;
  font-size: 13px;
}
.summary-grid .bx {
  font-size: 14px;
}
.summary-grid div:nth-child(2) {
  text-align: center;
}
.summary-grid div:last-child {
  text-align: right;
}
.modal-inner {
  max-height: 80vh;
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
}
/* Track */
/* Handle */
::-webkit-scrollbar-thumb {
  background: #d3c9c9;
  border-radius: 50px;
}
/* Handle on hover */
.cursor-pointer {
  cursor: pointer;
}
.color-red {
  color: red;
}
.bg-gray {
  background-color: #f6f8fa;
}
.color-white,
.white {
  color: white;
}
.table-border {
  border: 1px solid #ddd;
  border-radius: 5px;
}
.table-border tr {
  border: 1px solid #ddd;
}
.table-border tr td,
.table-border tr th {
  border: 1px solid #ddd;
  padding: 2px 5px;
  font-size: 14px;
}
.choose-passanger .grid-box a.qty-count {
  width: 32px;
  height: 32px;
  border-radius: 7px;
  border: 1px solid #d0d8e0;
  background: #f6f8fa;
  color: #a9a9a9;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
}
.choose-passanger .grid-box a.qty-count.qty-count--add {
  background: #d6ba86;
  color: black;
  border-color: #d6ba86;
}
.text-capitalize {
  text-transform: capitalize;
}
.banner > video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  object-fit: cover;
}
.banner .video-player-box video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  object-fit: cover;
  width: 100% !important;
  height: 700px !important;
}
.header-main {
  height: 150px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.jcc {
  display: flex;
  justify-content: center;
}
.icon-box {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  width: 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.icon-box.active {
  border-width: 3px;
  border-color: #F0D39C;
}
.icon-box span {
  font-size: 14px;
  margin-top: 10px;
}
.icon-box img {
  height: 40px;
}
.flight-dropdown .rc-select-item-option-content:before {
  content: "✈️";
  margin-right: 5px;
}
.flag-grid {
  display: grid;
  grid-template-columns: 35% 65%;
}
.flag-select .rc-select-selection-item {
  white-space: nowrap;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.pass-grid {
  display: grid;
  grid-template-columns: 20% 40% 40%;
}
.pass-grid li {
  font-size: 14px;
}
header nav.navbar.fixed-header {
  background: black !important;
}
header nav.navbar.fixed-header.white {
  background: white !important;
}
.container-fluid {
  --bs-gutter-x: 9rem;
}
.flight-booking .tab-content.modify_box {
  border: 1px solid #d0d8e0;
  border-radius: 12px;
  margin-top: 15px;
}
.custom-dropdown .dropdown-toggle {
  background: url(../assets/img/down-arrow.png) no-repeat right #ffffff;
  /*padding-right: 42px;*/
  background-size: 25px;
  background-position: top 12px right 15px;
  border: 1px solid #d0d8e0;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
  font-family: Poppins;
  line-height: 36px;
}
.custom-dropdown .dropdown-toggle::after {
  display: none;
}
.custom-dropdown .manage-box {
  width: 100%;
}
.custom-dropdown .manage-box h5 span {
  font-size: 16px;
}
.spacing-100 {
  padding: 100px 0;
}
.spacing-120 {
  padding: 120px 0;
}
.spacing-150 {
  padding: 150px 0;
}
header nav.navbar ul.navbar-nav.white {
  color: white !important;
}
header nav.navbar ul.navbar-nav.white .filter-icon {
  filter: contrast(0) brightness(2);
}
.cabin-option ul {
  gap: 0 10px !important;
}
.cabin-type-modal .cabin-option .box input[type="radio"]:checked + label {
  background: #d6ba86 !important;
  color: black !important;
}
.custom_title {
  margin: 0 0 20px;
  padding: 0 0 8px;
  color: black;
  border-bottom: 1px solid;
  border-bottom-color: #d0d8e0;
  font-weight: 600;
}
.dashboard-box .sidenav ul .accordion.custom-accordion:not(.active) {
  background: black !important;
}
.dashboard-box .sidenav ul .accordion.custom-accordion:not(.active) * {
  background: black !important;
  color: white;
}
.dashboard-box .sidenav ul .accordion.custom-accordion:not(.active) * .accordion-button::after svg {
  fill: white;
}
.dashboard-box .sidenav ul .accordion.custom-accordion .accordion-item button.accordion-button img {
  filter: inherit !important;
}
.track-flights .head-accordion {
  justify-content: space-between;
}
.card-header {
  font-weight: bold !important;
}
.sticky-box {
  position: sticky;
  top: 130px;
  z-index: 9;
  height: 84vh;
  overflow: auto;
  padding-right: 10px;
}
.sticky-check-box {
  position: sticky;
  top: 130px;
  z-index: 9;
}
.flight-info-box {
  position: relative;
  margin-top: 20px;
}
.flight-info-box .flight-type-box {
  margin-bottom: 15px;
  padding: 0 0 10px;
  border-bottom: 1px solid rgba(240, 211, 156, 0.53);
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
}
.flight-info-box .flight-type-box h6 {
  padding: 0;
  margin: 0;
  font-size: 0.85rem;
}
.flight-info-box .disabled-block {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.46);
  z-index: 9;
  border-radius: 5px;
}
.flight-info-box .book-flight-block {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.46);
  z-index: 15;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
.fare-icons-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
  margin-top: 10px;
}
.fare-icons-box img {
  height: 15px;
  cursor: pointer;
  margin-right: 10px;
}
.bag-tooltip {
  width: 250px;
  text-align: left;
}
.bag-tooltip .title {
  padding-bottom: 5px;
  text-align: left;
  border-bottom: 1px solid;
  margin-bottom: 10px;
}
.city-typehead .rc-select-selection-search-input {
  background-image: url('../../public/images2/search-black.png');
  background-position: 13px 14px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding-left: 42px !important;
}
.city-typehead .rc-select-selection-placeholder {
  padding-left: 42px !important;
}
.date-picker {
  background-image: url('../../public/images/calendar-icon.png');
  background-position: 13px 14px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding-left: 42px !important;
}
.cabin-icon,
.passenger-icon {
  height: 22px;
  margin-top: -3px;
  margin-right: 3px;
}
.header-group-btn {
  border: 1px solid #f1f1f1 !important;
  display: flex;
  padding: 5px 10px;
  background: white;
  border-radius: 100px;
}
.header-group-btn .dropdown-toggle::after {
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
  margin-left: 0;
  position: relative;
  right: 6px;
}
.header-group-btn li a {
  width: 60px;
  color: black !important;
  font-family: Poppins !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  height: 25px !important;
  line-height: 2;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}
.header-group-btn li a span {
  margin: 0 10px;
}
.header-group-btn li a img {
  height: 18px;
  margin-right: 10px;
}
.header-group-btn li a::after {
  content: "";
}
.header-group-btn li:first-child a {
  border-radius: 100px 0 0 100px;
  border-right: 1px solid #d6ba86;
}
.header-group-btn li:nth-child(2) a {
  border-right: 1px solid #d6ba86;
  margin-left: 7px !important;
}
.header-group-btn li:last-child a {
  margin-left: 7px !important;
}
.dropdown-menu {
  box-shadow: 0 0 24px 2px #5bc0de80;
  border: none;
}
.dropdown-menu.show {
  z-index: 9999;
}
.country-flag-list-box.show {
  left: -700px;
  top: 50px;
  padding: 10px 0;
  box-shadow: 0 0 24px 2px #5bc0de80;
  border: none;
  border-radius: 10px;
  position: relative;
  height: 80vh;
  overflow-x: auto;
}
.country-flag-list-box.show .title {
  padding: 15px 20px 15px;
  border-bottom: 1px solid #d6ba86;
  margin-bottom: 0;
}
.country-flag-list-box.show ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
  padding: 10px 20px;
}
.country-flag-list-box.show ul li {
  width: 180px;
  padding: 8px 10px;
  font-size: 0.85rem;
  display: block;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.6;
  text-transform: capitalize;
}
.country-flag-list-box.show ul li .currency-code {
  width: 35px !important;
  display: inline-block;
}
.country-flag-list-box.show ul li.active {
  color: #d6ba86;
  font-weight: bold;
}
.country-flag-list-box.show ul li:hover {
  background: #f1f1f1;
  border-radius: 5px;
}
.accordion-button {
  font-size: 0.85rem;
  color: #666 !important;
}
.content span {
  color: #666 !important;
}
.f-black {
  color: black;
}
.seat-layout {
  overflow-x: auto;
  height: 700px;
}
.seat-layout .seat-list {
  width: 54%;
  border-left: 2px solid #666;
  border-right: 2px solid #666;
  padding: 5px 0;
  margin: 10px 0;
}
.seat-layout .seat-list .row-wrapper {
  align-items: center;
}
.seat-layout .seat-list .deck-label {
  width: 100%;
  text-align: center;
}
.seat-layout .seat-list .header-title {
  padding: 5px;
  text-align: center;
  margin-bottom: 0;
}
.seat-layout .seat-list .seat {
  cursor: pointer;
}
.seat-layout .seat-list .seat-code {
  color: black !important;
}
.seat-layout .seat-list .seat-code.selected {
  color: white !important;
  font-weight: 600;
}
.seat-details-box {
  background: #f3f2f2;
  line-height: 1.7;
  font-size: 0.875rem;
}
.seat-details-box .seat-details {
  padding: 0 10px 10px;
  font-size: 0.8rem;
}
.seat-details-box .seat-details .selected-seat {
  color: #01276e;
  font-weight: 600;
}
.seat-details-box h6 {
  padding: 5px 10px;
  background: #d6ba86;
  font-size: 0.875rem;
  font-weight: 500;
}
.mr5 {
  margin-right: 5px;
}
.ssr-grid {
  display: grid;
  grid-template-columns: 35% 60%;
  grid-gap: 4%;
}
input[type="radio"] {
  height: 1rem;
  width: 1rem;
}
.font12 {
  font-size: 12px !important;
}
.f-12 {
  font-size: 12px;
}
.f-14 {
  font-size: 14px;
}
.f-16 {
  font-size: 16px;
}
.text-gray {
  color: #666;
}
.travel-wallet {
  border: 1px solid #fdf5e6;
  background: #fdf5e68c;
  line-height: 1.2;
}
.travel-wallet img {
  margin-right: 20px;
}
.travel-wallet .balance {
  font-size: 2rem;
  font-weight: 400;
  color: black;
  letter-spacing: 2px;
}
.travel-wallet .small-1 {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 10px;
}
.travel-wallet .d-block {
  font-weight: 500;
  font-size: 12px;
  color: #f59f00;
}
