@media screen and (min-width: 1440px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1430px !important;
    }
}

@media screen and (max-width: 1440px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 95% !important;
    }
}
