.rc-dialog-mask {
    /*z-index: 10001;*/
}

.rc-dialog-wrap {
    /*z-index: 10002;*/
}

.rc-dialog-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    border-bottom: 1px solid #f1f1f1;
    border-top-left-radius: calc(0.5rem - 0px);
    border-top-right-radius: calc(0.5rem - 0px);

    margin: 0 0 20px;
    padding: 0 0 8px;
    border-bottom: 1px solid;
    border-bottom-color: #d0d8e0;

}

.rc-dialog-title {
    color: #003b95;
    font-size: 1.25rem;
    font-weight: 500;
}

.rc-dialog-close {
    position: absolute;
    border: none;
    right: -14px;
    top: -14px;
    background-color: #ef4639;
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 50px;
    opacity: 1;
    text-shadow: none;
    line-height: 1.2;
}

.rc-dialog-close-x {
    width: .8em;
    height: .8em;
    font-style: normal;
    font-weight: 500;
    color: white;
}


.rc-dialog-close:hover {
    background-color: #ef4639 !important;
    /*opacity: 0.9;*/
}

.rc-dialog-close-x:after {
    /*content: '×';*/
    color: white !important;
    font-size: 28px;
    font-weight: 400;
}

.rc-dialog-content {
    max-height: fit-content;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.75rem 0 rgba(34, 48, 62, 0.14);
    transform: translateY(0) scale(1);
    padding: 1.5rem !important;
    overflow: visible;
}

.rc-dialog-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0;
    box-sizing: border-box;
}


.confirm-modal .rc-dialog-body {
    padding: 0 20px;
}

.confirm-modal .action-btn-group {
    display: flex;
    float: right;
    margin-bottom: 20px;
}

.confirm-modal .action-btn-group .btn {
    width: 20% !important;
    margin-left: 10px;
}

.confirm-modal .rc-dialog-title {
    font-size: 1rem !important
}
