.tooltip-box {
    max-width: 40%;
    /*background: gray;*/
    background: rgba(228, 228, 228, 0.61);
    opacity: 1;
    border-radius: 5px;
}

.tooltip-box .rc-tooltip-content {
}

.tooltip-box .rc-tooltip-inner {
    font-size: 13px;
    min-width: 30px;
    min-height: 32px;
    padding: 10px;
    color: black;
    /*text-align: left;*/
    text-decoration: none;
    word-wrap: break-word;
    /*background-color: rgba(0, 0, 0, 0.75);*/
    /*background-color: gray;*/
    background-color: #f1f1f1;
    -webkit-box-shadow: 0 2px 8px #5bc0de80;
    box-shadow: 0 2px 8px #5bc0de80;
    text-align: center;
    opacity: 1;
    font-family: Poppins;
}

.rc-tooltip-placement-top .rc-tooltip-arrow, .rc-tooltip-placement-topLeft .rc-tooltip-arrow, .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border-top-color: gray;
}
