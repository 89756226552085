.rc-tabs {
    border: none;
}

.rc-tabs-nav {
    margin-bottom: 15px;
}

.rc-tabs-nav-list {
    width: 100%;
}

.rc-tabs-tab {
    flex: 1;
    border-radius: 0.375rem;
}

.rc-tabs-tab-btn {
    width: 100%;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: black;
    padding: 0.5rem 1rem;
    font-family: Poppins;
    font-weight: 400;
}

.rc-tabs-tab-active {
    background-color: #d6ba86;
}

.rc-tabs-ink-bar {
    background: none;
}

.rc-tabs-tab-focus {
    outline: none;
}
