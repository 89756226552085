.popover-title {
    padding: 5px 10px;
    border-bottom: 1px solid #f1f1f1;
}

.popover-content {
    padding: 5px 10px;
    background-color: white;
}

.rc-tooltip-inner {
    border: none;
    min-width: 400px;
    padding: 5px 0;
}

.rc-tooltip-inner .table {
    margin-bottom: 0;
}
